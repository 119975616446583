
import React, { useEffect, useState } from "react";
import AppData from "../../../appData";
import { Button } from "../../../components/button/button";
import { DataGrid } from "../../../components/dataGrid/dataGrid";
import FlexBox from "../../../components/flexBox/flexBox";
import GridContainer from "../../../components/gridContainer/gridContainer";
import PopUpForm from "../../../components/popupForm/popupForm";
import Icons from "../../../icons/icons";
import AppTable from "../../appTable/appTable";
import BlockDiagramScreen from "../blockDiagramScreen/blockDiagramScreen";
import { ConfigLocalTable } from "./configLocalTable";
import ConfigRemoteTable from "./configRemoteTable";
import { CreateCustomer, CustomerSearch, GetHtml, ProcessFunction, UpdateCustomerId, UpdateSalesMarketID, addUserConfig, copyUserConfig, copyUserConfigRemote, customerDetailsSearch, downloadConfigFile, downloadNVMFile, generateStates, getBlockDiagramData, removeUserConfig, searchMarketSegment, searchSalesRegion, updateUserConfig } from "./configurationEvents";
import { configurationSearch, freezeConfig, functionSearch, getDeviceProperties } from "../blockDiagramScreen/blockDiagramEvents";
import Message from "../../../components/messages/messages";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import Tooltip from "../../../components/toolTip/toolTip";
import configJson from "../../../config.json"
let value: any = 2;
(value < 0) ? console.log("error") : console.log((value * 3.14 * 2), "val......", Math.round(value * 3.14 * 2));
const getFormElement = (data: any) => {
    var items = ['']
    data.inputs.map((val: any) => {
        try {
            for (const [key, value] of Object.entries(val)) {
                items.push(key + ' [' + value + ']')
            }

        }
        catch { }
    })
    var current = ''
    if (data.name === 'Frequency Stability*') {
        items.map((val) => {
            if (val.indexOf(AppData.ppmValue) > -1) {
                current = val
                AppData.ppmValue = val
            }
        })
        return ({ type: "select", id: data.name, label: data.name, contentItems: items, current: current, disable: current != '' ? true : false })
    }
    else {
        return ({ type: "select", id: data.name, label: data.name, contentItems: items })
    }
}

interface propsIF {
    localtabledata: any
    remotetabledata: any
    recentData?: any
    blockdiagramScreen?: any
    setShowMessage?: any
    MessageText?: any
}
function ConfigurationScreen({ localtabledata, remotetabledata, recentData, setShowMessage = false,MessageText = '' }: propsIF) {
    const [local, setlocal] = useState(true)
    const [Selectrow, setSelectrow] = useState({})
    const [active, setActive] = useState(false)
    const [configdata, setConfigData] = useState(localtabledata)
    const [remoteConfigdata, setRemoteConfigData] = useState(remotetabledata)
    const [recentdata, setRecentData] = useState(recentData)
    const [rowData, setRowData] = useState<any>()
    const [rowDataRemote, setRowDataRemote] = useState<any>()
    const [block, setblock] = useState<any>(AppData.block)
    const [data, setData] = useState<any>()
    const [copy, setCopy] = useState(false)
    const [edit, setEdit] = useState(false)
    const [render, setRender] = useState(false)
    const [renderPopup, setRenderPopup] = useState(false)
    const [freeze, setFreeze] = useState(false)
    const [message, setMessage] = useState()
    const [visible, setVisible] = useState(false)
    const [error, setError] = useState('')
    const [loader, setloader] = useState(false)
    const [loaderMessage, setLoaderMessage] = useState("Creating Addendum file")
    const [refresh, setRefresh] = useState(false)
    const [editRender, setEditRender] = useState(false)
    const [customerpopUp, SetcustomerPopup] = useState(false)
    const [pdf, setPdf] = useState(false)
    const [input, SetInput] = useState('')
    const [showMessageText,setShowMessagetext] = useState(setShowMessage)
    const [messageText,setMessageText] = useState(MessageText)
    useEffect(() => { 
        setConfigData(AppData.userConfig.data.local)
    },[AppData.userConfig.data.local])

    useEffect(() => { 
        setRecentData(AppData.userConfig.data.recent)
    },[AppData.userConfig.data.recent])
    
    console.log('localtable',configdata)

    const setMessageComp = (visible: boolean, message: any) => {
        return setMessage(message), setVisible(visible)
    }
    var addingForm = require('./createconfig.json')
    var customerForm = require("./createcustomer.json")
    const handler = () => {
        addingForm.data[0].current = ''
        addingForm.data[0].disable = false
        AppData.variant = ''
        setVisible(false)
        setError('')
        setRefresh(true)
        setActive(true)
        AppData.devicelist = []
        AppData.variant = addingForm.data[0].current
        AppData.device.data.local.map((devicename: any) => {
            return (
                AppData.devicelist.push(devicename['variant'])
            )
        })
    }
    const formSubmit = (formData: { id: string, value: string }[]) => {
        //OnSubmit of create configuration
        console.log('formdata', formData)
        let count = false
        let basePart = formData[0].value
        formData.map((val) => {
            if (val.id == 'name') {
                AppData.userConfig.data.local.map((local: any) => {
                    if ((local.name === val.value) && (local.variant === basePart)) {
                        count = true
                    }
                })
                if (count) {
                    //Through error if existing name is submitted
                    setError('Please enter a unique configuration name')
                    setRefresh(true)
                    setActive(true)
                }
                else {
                    const newValue = val.value.trim()
                    if (error === '') {
                        if ((newValue != '') && (AppData.variant != '')) {
                            let data = formData.map(item => { return (`${item.id}:${item.value}`) })
                            if (active) {
                                addUserConfig(data, Selectrow, setConfigData, setRemoteConfigData).then((res) => {
                                    setRecentData(res);
                                    setMessageComp(true, 'Configuration has been created')
                                })
                            }
                            AppData.devicelist = []
                            setRefresh(false)
                            setActive(false)
                            setError('')
                        }
                        else {
                            //Show error when fields are not filled.
                            setError('Please enter the required fields')
                            setRefresh(true)
                            setActive(true)

                        }
                    }
                    else if (error != '') {
                        setRefresh(true)
                        setActive(true)
                    }
                }
            } else {
                // setError('')
            }

        })

    }
    const handleCopy = () => {
        setError('')
        setEditRender(true)
        setEdit(false)
        setCopy(true)
    }
    const handleEdit = () => {
        setError('')
        setEditRender(true)
        setEdit(true)
        setCopy(true)
    }
    const copySubmit = (formData: { id: string, value: string }[]) => {
        var checkName = 0, checkDescription = 0
        setError('')
        let count = false
        formData.map((val) => {

            if (val.id == 'name') {
                const newValue = val.value.trim()
                AppData.userConfig.data.local.map((local: any) => {
                    if (local.name === newValue) {
                        count = true
                    }
                })
                if ((newValue != '')) {
                    if (count) {
                        checkName = 0
                        setError("Please enter a unique configuration name")
                        setEditRender(true)
                        setCopy(true)
                    }
                    else {
                        if(newValue.length>60){
                            checkName = 0
                            setError('Configuration name must contain less than 60 characters.')
                            setCopy(true)
                            setEditRender(true)
                        }
                        else{checkName = 1}
                    }
                }
                else {
                    checkName = 0
                    //If empty field throw error
                    setError('Please enter the required fields')
                    setCopy(true)
                    setEditRender(true)

                }
            }
            else if (val.id === 'description'){
                if(val.value.length > 255){
                    checkDescription = 0
                    setError('Description must contain less than 255 characters.')
                    setCopy(true)
                    setEditRender(true)
                }
                else {checkDescription = 1}
            }
        })
        if ((checkDescription === 1) && (checkName === 1)) {
            let formdata = formData.map(item => { return (`${item.id}:${item.value}`) })
            copyUserConfig(formdata, rowData, setConfigData, setRecentData)
            setCopy(false)
            setEditRender(false)
            setblock(false)
            setMessageComp(true, 'Duplicate configuration has been created')
            setError('')
        }
    }
    const onDuplicateChange = (value: string, id: string) => {
        if (id === 'name') {
            const newValue = value.trim()
            if (newValue === '') {
                setError('Enter the configuration name')
            }
            else if (newValue.length > 60) { setError('Configuration name must contain less than 60 characters.') }
            else if (newValue != '') { setError('') }
        }
        else if (id === 'description') {
            if (value.length > 255) { setError('Description must contain less than 255 characters.') }
            else { setError('') }
        }
    }
    const onEditChange = (value: string, id: string) => {
        if (id === 'name') {
            const newValue = value.trim()
            if (newValue === '') {
                setError('Enter the configuration name')
            }
            else if (newValue.length > 60) { setError('Configuration name must contain less than 60 characters.') }
            else if (newValue != '') { setError('') }
        }
        else if (id === 'description') {
            if (value.length > 255) { setError('Description must contain less than 255 characters.') }
            else { setError('') }
        }
    }
    const onCloseForm = () => {
        setEditRender(false)
    }
    const onChangeConfigName = (value: string, id: string) => {
        if (id === 'name') {
            if (value.length > 60) { setError('Configuration name must contain less than 60 characters.') }
            else { setError('') }
        }
        else if (id === 'description') {
            if (value.length > 255) { setError('Description must contain less than 255 characters.') }
            else { setError('') }
        }
    }
    const editSubmit = (formData: { id: string, value: string }[]) => {
        var checkName = 0
        var checkDescription = 0
        //On edit submit check if name is empty and show error accordingly
        formData.map((val) => {
            if (val.id == 'name') {
                const newValue = val.value.trim()
                if ((newValue != '')) {
                    if (newValue.length > 60) {
                        checkName = 0
                        setError('Configuration name must contain less than 60 characters.')
                        setCopy(true)
                        setEditRender(true)
                    }
                    else {
                        checkName = 1
                    }
                }
                else if ((newValue === '') && (error === '')) {
                    if (val.id == 'name') {
                        val.value = AppData.currentConfig.name
                    }
                    checkName = 1
                }
                else {
                    checkName = 0
                    setError('Please enter the required fields')
                    setCopy(true)
                    setEditRender(true)

                }
            }
            else if (val.id === 'description') {
                if (val.value.length > 255) {
                    checkDescription = 0
                    setError('Description must contain less than 255 characters.')
                    setCopy(true)
                    setEditRender(true)
                }
                else { checkDescription = 1 }
            }
        })
        if ((checkName === 1) && (checkDescription === 1)) {
            let formdata = formData.map(item => { return (`${item.id}:${item.value}`) })
            updateUserConfig(formdata, rowData, setConfigData, setRecentData)
            setCopy(false)
            setMessageComp(true, 'Configuration is updated successfully')
            setEditRender(false)
            setError('')
            setRender(!render)
        }
    }
    const deleteConfig = () => {
        removeUserConfig(rowData, setConfigData, setRecentData)
        AppData.block = false
        setMessageComp(true, `${AppData.currentConfig.name} configuration was deleted successfully`)
    }
    const getRowData = (data: any) => {
        console.log('rowdata', data)
        MessageText=''
        setShowMessage = false
        setlocal(true)
        setRowData(data)
        setData(data)
    }
    const getRowDataRemote = (data: any) => {
        console.log('rowdata', data)
        setRowDataRemote(data)
        setData(data)
    }
    const copySubmitRemote = (formData: { id: string, value: string }[]) => {
        console.log('formdata', formData)
        var checkName=0,checkDescription=0
        setError('')
        let count=false
        formData.map((val)=>{
            if(val.id == 'name'){
                const newValue = val.value.trim()
                AppData.userConfig.data.remote.map((remote:any)=>{
                if(remote.name === newValue){
                    count = true
                }
                })
                if(newValue !== ''){
                    if(count){
                        checkName=0
                        setError("Please enter a unique configuration name")
                        setEditRender(true)
                        setCopy(true)
                    }
                    else {
                        if(newValue.length>60){
                            checkName=0
                            setError('Configuration name must contain less than 60 characters.')
                            setCopy(true)
                            setEditRender(true)
                        }
                        else{checkName=1}
                    }
                }
                else{
                    checkName = 0
                    setError('Please enter the required fields')
                    setCopy(true)
                    setEditRender(true)
                }
            }
            else if (val.id === 'description'){
                if(val.value.length > 255){
                    checkDescription = 0
                    setError('Description must contain less than 255 characters.')
                    setCopy(true)
                    setEditRender(true)
                }
                else {checkDescription = 1}
            }
        })
        if((checkName === 1) && (checkDescription === 1)){
        let formdata = formData.map(item => { return (`${item.id}:${item.value}`) })
        copyUserConfigRemote(formdata, rowDataRemote, setRemoteConfigData)
        setCopy(false)
        setEditRender(false)
        setblock(false)
        setMessageComp(true, 'Duplicate configuration has been created')
        setError('')
        }
    }

    function freezeFormData() {
        AppData.freezeFormData = { "freeze": [] }
        let formData: any
        AppData.devicePropertyData.map((data: any) => {
            if (data.name == 'Temperature Range' ||
                data.name == 'Customer Ordering Code' ||
                data.name == 'Frequency Stability' ||
                data.name == 'Packaging') {
                data.name += "*"
                formData = getFormElement(data)
                AppData.freezeFormData.freeze.push(formData)
            }
        })
    }
    let [online, isOnline] = useState(navigator.onLine);
    const setOnline = () => {
        console.log('We are online!');
        isOnline(true);
        configurationSearch(setConfigData, setRemoteConfigData, setRecentData)
    };
    const setOffline = () => {
        console.log('We are offline!');
        isOnline(false);
        AppData.userConfig.data.remote = [];
    };

    // Register the event listeners
    useEffect(() => {
        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);

        // cleanup if we unmount
        return () => {
            window.removeEventListener('offline', setOffline);
            window.removeEventListener('online', setOnline);
        }
    }, []);

    const handleFreeze = () => {
        setVisible(false)
        setError('')
        AppData.label = ''
        AppData.functionFormData = {
            "param": [],
            "commonfunctions": [],
            "delay": [],
            "type": [],
            "differential": [],
            "single": [],
            "op": [],
            "singlecommon": [],
            "singlevoh": [],
            "diffvoh": [],
            "slew": [],
            "diffref": [],
            "freq": [],
            "vdd": []
        }
        if (AppData.currentConfig.custom_part_number) {
            alert("Configuration already Frozen. Cannot Freeze again")
            return;
        }
        // freezeConfig(setFreeze)
        getDeviceProperties().then((val: any) => { freezeFormData(); setFreeze(true) })
    }
    const FreezeClose = () => {
        setError('')
        AppData.freeze = []
    }
    const handleFreezeSubmit = (formData: { id: string, value: string }[]) => {
        AppData.freezeData = []
        AppData.freeze[2] = AppData.ppmValue
        setError('')
        AppData.freeze.map((data: any) => {
            let val = data.split(' ')
            var freezeInputs = val[0]
            AppData.freezeData.push(freezeInputs)
        })
        if (AppData.freezeData.length == 4) {
            freezeConfig(setFreeze, setConfigData, setRemoteConfigData, setRecentData, setMessageComp).then((val) => {
                setFreeze(false)
                setRender(!render)
            })
            AppData.freezeFormData = { "freeze": [] }
            AppData.freeze = []
            setError('')
        }
        else {
            setFreeze(true)
            setError('Enter all the properties to freeze the configuration.')
        }
    }

    const downloadConfig  = async () => {
        console.log(AppData.currentConfig.udc_uuid)
        try {
            setloader(true)
            setLoaderMessage("Downloading Config files")
            await generateStates("local")
            await downloadConfigFile("local")
            await downloadNVMFile("local")
            setloader(false)
        }catch { 
            setloader(false)
        }
    }

    const downloadConfigRemote = async () => {
        console.log(AppData.currentConfig.udc_uuid)
        try {
            setloader(true)
            setLoaderMessage("Downloading Config files")
            await generateStates("remote")
            await downloadConfigFile("remote")
            await downloadNVMFile("remote")
            setloader(false)
        }catch { 
            setloader(false)
        }
    }

    const customerHandle = async () => {
        setVisible(false)
        //OnCLick of view addendum button check if customer details is present for the particular configuration
        customerForm.data[1].content = ""
        customerForm.data[2].content = ""
        customerForm.data[3].content = ""
        customerForm.data[4].content = ""
        customerForm.data[7].content = ""
        customerForm.data[8].content = ""
        customerForm.data[9].content = ""
        customerForm.data[12].content = ""
        customerForm.data[1].disabled = false
        customerForm.data[2].disabled = false
        customerForm.data[3].disabled = false
        customerForm.data[4].disabled = false
        AppData.customerDetails = []
        AppData.marketSegment = []
        AppData.salesRegion = []
        AppData.marketId = ""
        AppData.salesId = ""
        AppData.application_description = ""
        AppData.end_product_program_name = ""
        AppData.mass_production_date = ""
        AppData.email_pdf_Datasheet_to = ""
        AppData.sales_comments = ""
        setError('')
        var market = await searchMarketSegment().then((data: any) => {
            var marketList = ['']
            var details = ['']
            data.map((marketData: any, key: number) => {
                details[key] = marketData
                marketList[key] = marketData.name
            })
            AppData.marketSegment = marketList
            AppData.marketDetails = details
        })
        var sales = await searchSalesRegion().then((data: any) => {
            var salesList = ['']
            var details = ['']
            data.map((salesData: any, key: number) => {
                details[key] = salesData
                salesList[key] = salesData.name
            })
            AppData.salesRegion = salesList
            AppData.salesDetails = details
        })
        customerForm.data[6].contentItems = AppData.marketSegment
        customerForm.data[11].contentItems = AppData.salesRegion
        AppData.customerDetails = await customerDetailsSearch()
        if (AppData.currentConfig.product_details != null && AppData.currentConfig.product_details.market_segment != null && AppData.currentConfig.product_details.market_segment != "") {
            customerForm.data[7].content = AppData.currentConfig.product_details.application_description
            customerForm.data[8].content = AppData.currentConfig.product_details.end_product_program_name
            customerForm.data[9].content = AppData.currentConfig.product_details.mass_production_date
            AppData.mass_production_date = AppData.currentConfig.product_details.mass_production_date
            AppData.end_product_program_name = AppData.currentConfig.product_details.end_product_program_name
            AppData.application_description = AppData.currentConfig.product_details.application_description
            AppData.marketDetails.map((data: any) => {
                if (AppData.currentConfig.product_details.market_segment === data._id) {
                    customerForm.data[6].current = data.name
                    AppData.marketId = data._id
                }
            })
        }
        else { customerForm.data[6].current = "" }
        if (AppData.currentConfig.sales_details != null && AppData.currentConfig.sales_details.sales_region != null && AppData.currentConfig.sales_details.sales_region != "") {
            customerForm.data[12].content = AppData.currentConfig.sales_details.sales_comments
            AppData.sales_comments = AppData.currentConfig.sales_details.sales_comments
            AppData.salesDetails.map((data: any) => {
                if (AppData.currentConfig.sales_details.sales_region === data._id) {
                    AppData.salesId = data._id
                    customerForm.data[11].current = data.name
                }
            })
        }
        else { customerForm.data[11].current = "" }
        if (AppData.currentConfig.customer_details_id !== "" && AppData.currentConfig.customer_details_id !== null) {
            var response = await CustomerSearch(AppData.currentConfig.customer_details_id)
            customerForm.data[1].content = response[0].customer_company
            customerForm.data[2].content = response[0].customer_address
            customerForm.data[3].content = response[0].customer_contact_name_title
            customerForm.data[4].content = response[0].customer_contact_number
            AppData.Customer.comapany_name = response[0].customer_company
            AppData.Customer.address = response[0].customer_address
            AppData.Customer.name = response[0].customer_contact_name_title
            AppData.Customer.number = response[0].customer_contact_number
            customerForm.data[1].disabled = true
            customerForm.data[2].disabled = true
            customerForm.data[3].disabled = true
            customerForm.data[4].disabled = true
            SetcustomerPopup(true)
        } else {
            customerForm.data[1].disabled = false
            customerForm.data[2].disabled = false
            customerForm.data[3].disabled = false
            customerForm.data[4].disabled = false
            AppData.Customer.address = ""
            AppData.Customer.comapany_name = ''
            AppData.Customer.name = ""
            AppData.Customer.number = ""
            SetcustomerPopup(true)
        }
    }

    function containsNumbers(str: string) {
        var numbers = /^[0-9]+$/;
        //var numbers=/^\+?(?:[0-9]\x20?[-]?){6,14}[0-9]\s*$/gm
        return numbers.test(str);
    }


    const CustomerDataSubmit = async (formdata: { id: string, value: string }[]) => {
        setloader(true)
        setLoaderMessage("Creating Addendum file")
        //Handling the customer details popup form submit
        AppData.html = ''
        var customerCompany: any
        var customerAdress: any
        var customerName: any
        var customerNumber: any
        var marketID: any
        var salesID: any

        try {
            if (AppData.currentConfig.customer_details_id === null) {
                formdata.map((val: any, key: number) => {
                    if (val.id === "company_name") {
                        AppData.Customer.comapany_name = val.value
                    }
                    else if (val.id === "address") {
                        if (AppData.Customer.address === '') {
                            AppData.Customer.address = val.value
                        }
                    }
                    else if (val.id === "name") {
                        if (AppData.Customer.name === '') {
                            AppData.Customer.name = val.value
                        }
                    }
                    else if (val.id === "number") {
                        if (AppData.Customer.number === '') {
                            AppData.Customer.number = val.value
                        }
                    }
                    else if (val.id === 'market') {
                        AppData.marketDetails.map((data: any) => {
                            if (val.value === data.name) {
                                marketID = data._id
                                AppData.marketId = data._id
                            }
                        })
                    }
                    else if (val.id === 'sales') {
                        AppData.salesDetails.map((data: any) => {
                            if (val.value === data.name) {
                                salesID = data._id
                                AppData.salesId = data._id
                            }
                        })
                    }
                })
                if ((AppData.Customer.comapany_name === '') || (AppData.Customer.name === '') || (AppData.Customer.number === '')) {
                    setError('Enter all the required fields')
                    setloader(false)
                }
                else { setError('') }

                if (!containsNumbers(AppData.Customer.number) && AppData.Customer.number !== "") {
                    setError('Enter Number')
                    setloader(false)
                }
                else if (AppData.Customer.comapany_name !== "" && AppData.Customer.name !== "" && AppData.Customer.number !== "") {
                    var customer_id: any
                    var customerFound: any
                    customerFound = false
                    AppData.customerDetails.forEach((val: any) => {
                        if (val.customer_company === AppData.Customer.comapany_name) {
                            customer_id = val._id
                            customerFound = true
                        }
                    })
                    if (!customerFound) {
                        var customer_id_res = await CreateCustomer(AppData.Customer.comapany_name, AppData.Customer.address, AppData.Customer.name, AppData.Customer.number)
                        customer_id = customer_id_res[0]._id
                    }
                    var current = await UpdateCustomerId(customer_id, AppData.currentConfig.udc_uuid)
                    AppData.api.deviceSearchWithId.bodyParams = { _id: current.device_id }
                    var currentDevice = await AppData.localServer.sendCommand(AppData.api.deviceSearchWithId, `Getting current Device failed`, 1)
                    current['platform'] = currentDevice[0].platform
                    current['variant'] = currentDevice[0].variant
                    AppData.currentConfig = current;
                    var id = await UpdateSalesMarketID()
                    configurationSearch(setConfigData, setRemoteConfigData, setRecentData)
                    await generateStates(AppData.server)
                    var html = await GetHtml(AppData.Customer.comapany_name, AppData.Customer.address, AppData.Customer.name, AppData.Customer.number)
                    AppData.html = html
                    setloader(false)
                    setPdf(true)
                    SetcustomerPopup(false)
                }
            } else {
                var id = await UpdateSalesMarketID()
                configurationSearch(setConfigData, setRemoteConfigData, setRecentData)
                await generateStates(AppData.server)
                var Html = await GetHtml(AppData.Customer.comapany_name, AppData.Customer.address, AppData.Customer.name, AppData.Customer.number)
                AppData.html = Html
                setloader(false)
                setPdf(true)
                SetcustomerPopup(false)
            }
        }
        catch {
            setloader(false)
        }
    }
    const OnChangeCustomerInput = (value: any, id: any) => {
        console.log('val555', id, value)
        //On value change in customer details popup form
        var customerFound: boolean
        customerFound = false
        setError('')
        if (id === 'company_name') {
            AppData.Customer.comapany_name = value
            AppData.customerDetails.forEach((val: any) => {
                if (id === "company_name" && val.customer_company === value) {
                    AppData.Customer.comapany_name = val.customer_company
                    AppData.Customer.address = val.customer_address
                    AppData.Customer.name = val.customer_contact_name_title
                    AppData.Customer.number = val.customer_contact_number

                    customerForm.data[1].content = val.customer_company
                    customerForm.data[2].content = val.customer_address
                    customerForm.data[3].content = val.customer_contact_name_title
                    customerForm.data[4].content = val.customer_contact_number
                    customerForm.data[2].disabled = true
                    customerForm.data[3].disabled = true
                    customerForm.data[4].disabled = true
                    customerFound = true
                    setRenderPopup(!renderPopup)
                }
            })
        }
        else if (id === 'name') { AppData.Customer.name = value }
        else if (id === 'number') { AppData.Customer.number = value }
        else if (id === 'address') { AppData.Customer.address = value }
        else if (id === 'appDescription') { AppData.application_description = value }
        else if (id === 'programName') { AppData.end_product_program_name = value }
        else if (id === 'saleComment') { AppData.sales_comments = value }
        else if (id === 'date') { AppData.mass_production_date = value }
        if ((id === 'sales') || (id === 'market') || (id === 'appDescription') || (id === 'programName') || (id === 'saleComment') || (id === 'date')) {
            if (id === 'market') {
                customerForm.data[6].current = value
                AppData.marketDetails.map((data: any) => {
                    if (value === data.name) {
                        AppData.marketId = data._id
                    }
                })
                setRenderPopup(!renderPopup)
            }
            else if (id === 'sales') {
                customerForm.data[11].current = value
                AppData.salesDetails.forEach((data: any) => {
                    if (value === data.name) {
                        AppData.salesId = data._id
                    }
                })
                setRenderPopup(!renderPopup)
            }
        }
        else if ((customerFound == false)) {
            customerForm.data[2].disabled = false
            customerForm.data[3].disabled = false
            customerForm.data[4].disabled = false
            AppData.Customer.address = ''
            AppData.Customer.name = ""
            AppData.Customer.number = ""
            customerForm.data[2].content = ''
            customerForm.data[3].content = ''
            customerForm.data[4].content = ''
            setRenderPopup(!renderPopup)
        }
    }

    //Details of table present in configuration screen. 
    const localcolumns = (configJson.WEB_ONLY === 0) ? [{ width: "20vw", toolbar: false, header: "Name" },
    { width: "15vw", toolbar: false, header: "Status" },
    { width: "15vw", toolbar: false, header: "Base Part" },
    { width: "20vw", toolbar: false, header: "Last Opened" },
    { width: "20vw", toolbar: false, header: "" }] :
        [{ width: "30vw", toolbar: false, header: "Name" },
        { width: "20vw", toolbar: false, header: "Base Part" },
        { width: "20vw", toolbar: false, header: "Last Opened" },
        { width: "20vw", toolbar: false, header: "" }]

    const remotecolumns = (configJson.WEB_ONLY === 0) ? [{ width: "20vw", toolbar: false, header: "Name" },
    { width: "15vw", toolbar: false, header: "Status" },
    { width: "15vw", toolbar: false, header: "Base Part" },
    { width: "20vw", toolbar: false, header: "Last Opened" },
    { width: "20vw", toolbar: false, header: "" }] :
        [{ width: "30vw", toolbar: false, header: "Name" },
        { width: "20vw", toolbar: false, header: "Base Part" },
        { width: "20vw", toolbar: false, header: "Last Opened" },
        { width: "20vw", toolbar: false, header: "" }]

    const recentcolumns = [{ width: "280px", toolbar: false, header: "Name" },
    { width: "220px", toolbar: false, header: "Status" },
    { width: "260px", toolbar: false, header: "Platform" },
    { width: "250px", toolbar: false, header: "Last Opened" },
    { width: "270px", toolbar: false, header: "" }]
    const blockscreenHandle = (value: boolean) => {
        AppData.block = value
        setRender(!render)
    }
    const configBack = (value: boolean) => {
        setActive(false)
        return (value == true) ? AppData.block = false : AppData.block = true
    }
    const backConfig = () => {
        configurationSearch(setConfigData, setRemoteConfigData, setRecentData)
        setActive(false)
        setlocal(true)
    }
    var formData = require('./editconfig.json')

    if (edit) {
        //If edit popup is selected content and placeholder is assigned
        formData.edit[0].placeHolder = 'Enter Configuration name'
        formData.edit[1].placeHolder = AppData.currentConfig.description

        formData.edit[0].content = AppData.currentConfig.name
        formData.edit[1].content = AppData.currentConfig.description
    }
    else {
        //If duplicate popup is selected content and placeHolder is assigned
        formData.duplicate[0].content = ''
        formData.duplicate[1].content = ''
    }
    const onCloseCreateForm = () => {
        AppData.variant = ''
        setRefresh(false)
        setError('')
    }
    return (
        <GridContainer shape="configarationMain" layout="configarationMain">
            <Message visible={visible} shape="config" font="config" position="bottom-right" text={message} hide={setVisible}></Message>
            {loader && <LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={loaderMessage} position={{ top: '40vh', left: '45vw' }} type={'circular'} disabled={false} ></LoadingSpinner>}
            {AppData.block ? <BlockDiagramScreen MessageText={messageText} setShowMessage={showMessageText} configBackButton={backConfig} configBack={configBack} configData={data} onSync={setRemoteConfigData} recentData={setRecentData} onClickDelete={deleteConfig} setBlock={setblock} setConfigData={setConfigData} setRemoteData={setRemoteConfigData} setRecentData={setRecentData} /> : <FlexBox gridName="b" shape="hug" spacing="remote" layout="vertical-top-left">
                <FlexBox shape="confugaration1" spacing="none" layout="horizontal-top-left">
                    <div style={{ width: "213px", height: "40px", display: "flex", gap: "7px", paddingRight: '0px', margin: '0px' }}><p style={{ fontWeight: "500", fontSize: "26px", fontFamily: "Ubuntu", margin: '0px', padding: '0px' }}>Configurations</p><p style={{ fontWeight: "400", fontSize: "26px", color: "#888888", fontFamily: 'Ubuntu', margin: '0px', padding: '0px' }}>{(configJson.WEB_ONLY === 0) ? AppData.userConfig.data.local.length + AppData.userConfig.data.remote.length + AppData.userConfig.data.recent.length : AppData.userConfig.data.local.length + AppData.userConfig.data.recent.length}</p></div>
                    <FlexBox spacing="none" shape="createNew" layout="horizontal-top-right">
                        <Tooltip shape='menu' fill='grey' spacing='normal' message={'create new configuration'} X={'-210px'} Y={'-35px'} location='left' >
                            <Button fill="createNew" color='signin' shape="createNew" spacing="createNew" onClick={handler} ><div style={{ display: 'flex', padding: '0px', margin: '0px', width: '100px', height: "21px", fontSize: '14px', fontWeight: "400", justifyContent: 'center', alignItems: 'center' }}>Create new</div></Button>
                        </Tooltip>
                    </FlexBox>
                    {refresh ? <PopUpForm valueChange={(value: string, id: string) => { onChangeConfigName(value, id) }} OnclickCancel={onCloseCreateForm} shape={"hug"} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={addingForm.data} onSubmit={formSubmit} visible={active} hide={setActive} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={AppData.devicelist}
                        buttonContent="Create Configuration" buttonName="popupform1" heading="Create New Configuration" Error={error}></PopUpForm> : <></>}
                    {freeze ? <PopUpForm form='FreezeForm' OnclickCancel={FreezeClose} shape={"hug"} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={AppData.freezeFormData.freeze} onSubmit={handleFreezeSubmit} visible={freeze} hide={setFreeze} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}
                        buttonContent={"Freeze"} buttonName="popupform1" heading={"Freeze Configuration"} subHeading="* required field" Error={error}></PopUpForm> : <></>}
                    {editRender ? <PopUpForm OnclickCancel={onCloseForm} shape={"hug"} valueChange={edit ? (value: any, id: any) => { onEditChange(value, id) } : (value: any, id: any) => { onDuplicateChange(value, id) }} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={edit ? formData.edit : formData.duplicate} onSubmit={edit ? editSubmit : (local === true)?copySubmit:copySubmitRemote} visible={copy} hide={setCopy} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}
                        buttonContent={edit ? "Save Changes" : "Duplicate Configuration"} buttonName="popupform1" heading={edit ? "Edit Configuration" : "Duplicate Configuration"} Error={error}></PopUpForm> : <></>}
                    {customerpopUp ? <PopUpForm customerPopup={true} shape={"customerpopup"} subHeading="* required field" valueChange={(value: any, id: any) => { OnChangeCustomerInput(value, id) }} spacing={"popupform1"} fill={"white"} stroke={""} font={""} initFormData={customerForm.data} onSubmit={CustomerDataSubmit} visible={customerpopUp} hide={SetcustomerPopup} popupformshape={"popupform1"} popupfill={"popupform"} popupstroke={"popupform"} items={[]}
                        buttonContent={"Generate Addendum"} buttonName="popupform1" heading={"Enter Details"} Error={error}></PopUpForm> : <></>}
                </FlexBox>
                <FlexBox shape="confugaration2" spacing="devicetablebutton" layout="vertical-top-left">
                    <div style={{ width: "189px", height: "33px", display: "flex", gap: "7px" }}><p style={{ fontWeight: "400", fontSize: "22px", margin: '0px', padding: '0px' }}>Recent</p><p style={{ fontWeight: "400", fontSize: "22px", color: "#888888", margin: '0px', padding: '0px' }}>{AppData.userConfig.data.recent.length}</p></div>
                    {/* <DataGrid fill="recent" shape="recent" data={[]} columns={[{width:"269px", toolbar:false, header:"Name"},{width:"259px", toolbar:false, header:"Status"},{width:"259px", toolbar:false, header:"Platform"},{width:"250px", toolbar:false, header:"LastEdited"},]}></DataGrid> */}
                    <ConfigLocalTable onSelectSetMessage={(value:any,message:any)=>{return setShowMessagetext(value),setMessageText(message)}} onSelect={() => { setVisible(false) }} OnclickViewAddendum={customerHandle} handleCopy={handleCopy} handleEdit={handleEdit} handleFreezeSubmit={handleFreezeSubmit} handleFreeze={handleFreeze} configData={setConfigData} onclickConfig={blockscreenHandle} recentData={setRecentData} onClickDelete={deleteConfig} onSelectRow={getRowData} onSubmitDuplicate={copySubmit} onSubmitEdit={editSubmit} onSync={setRemoteConfigData} data={recentdata} onClickDownLoadConfig = {downloadConfig} shape='recentConfig' fill="table1" stroke="rowOnly" Columns={localcolumns} />
                    {/* <AppTable data={actualData} shape='recent' fill="table1" stroke="rowOnly" Columns={recentcolumns} /> */}
                </FlexBox>
                <FlexBox shape="confugaration3" spacing="devicetablebutton" layout="vertical-top-left" >
                    <p style={{ display: 'flex', margin: '0px', fontWeight: 400, fontSize: '22px', fontStyle: 'normal', fontFamily: 'Ubuntu', gap: '7px' }}>All Configurations <div style={{ fontWeight: "400", fontSize: "22px", color: "#888888", fontFamily: 'Ubuntu', margin: '0px', padding: '0px' }}>{(configJson.WEB_ONLY === 0) ? AppData.userConfig.data.local.length + AppData.userConfig.data.remote.length : AppData.userConfig.data.local.length}</div></p>
                    {(configJson.WEB_ONLY === 0) && <FlexBox shape="hug" layout="horizontal-center-left" spacing="none">
                        <Button fill={(local == true) ? 'local' : 'remote'} spacing="none" shape="localButton" onClick={() => { return setlocal(true) }}>
                            <Icons iconName={"monitor"} size={"14px"} stroke="white"></Icons>
                            <p style={{ margin: '0px', fontSize: '14px', fontWeight: 400, fontStyle: "normal", color: 'white' }}>Local {AppData.userConfig.data.local.length}</p>
                        </Button>
                        <Button fill={(local == false) ? 'local' : 'remote'} spacing="none" shape="remoteButton" onClick={() => { return setlocal(false), setConfigData(AppData.userConfig.data.local), setRecentData(AppData.userConfig.data.recent), setRemoteConfigData(AppData.userConfig.data.remote) }}>
                            <Icons iconName={'cloud'} size={'18px'} fill='white' stroke="white"  ></Icons>
                            <p style={{ height: '21px', width: 'auto', margin: '0px', display: 'flex', fontSize: '14px', fontWeight: 400, fontStyle: "normal", color: 'white', alignItems: 'center', justifyContent: "center" }}>Remote {AppData.userConfig.data.remote.length}</p>
                        </Button>
                    </FlexBox>}
                    <FlexBox shape="hug" spacing="none" layout="vertical-top-left">
                        {(configJson.WEB_ONLY === 0) ? (local === true) ? <ConfigLocalTable onSelectSetMessage={(value:any,message:any)=>{return setShowMessagetext(value),setMessageText(message)}} onSelect={() => { setVisible(false) }} hide={setPdf} OnclickViewAddendum={customerHandle} pdfhandle={pdf} handleCopy={handleCopy} handleEdit={handleEdit} handleFreezeSubmit={handleFreezeSubmit} Error={error} handleFreeze={handleFreeze} configData={setConfigData} onclickConfig={blockscreenHandle} recentData={setRecentData} onClickDelete={deleteConfig} onSelectRow={getRowData} onSubmitDuplicate={copySubmit} onSubmitEdit={editSubmit} onSync={setRemoteConfigData} data={configdata} onClickDownLoadConfig = {downloadConfig} shape='config' fill="table1" stroke="rowOnly" Columns={localcolumns} /> :
                            <ConfigRemoteTable onSelectSetMessage={(value:any,message:any)=>{return setShowMessagetext(value),setMessageText(message)}} pdfHandle={pdf} hide={setPdf} OnclickViewAddendum={customerHandle} recentData={setRecentData} onSync={setConfigData} onclickConfig={blockscreenHandle} onSelectRow={getRowDataRemote} handleCopy={handleCopy} onSubmitDuplicate={copySubmitRemote} data={remoteConfigdata} onClickDownLoadConfig = {downloadConfigRemote} shape='config' fill="table1" stroke="rowOnly" Columns={remotecolumns} /> : <ConfigLocalTable onSelectSetMessage={(value:any,message:any)=>{return setShowMessagetext(value),setMessageText(message)}} onSelect={() => { setVisible(false) }} hide={setPdf} OnclickViewAddendum={customerHandle} pdfhandle={pdf} handleCopy={handleCopy} handleEdit={handleEdit} handleFreezeSubmit={handleFreezeSubmit} Error={error} handleFreeze={handleFreeze} configData={setConfigData} onclickConfig={blockscreenHandle} recentData={setRecentData} onClickDelete={deleteConfig} onSelectRow={getRowData} onSubmitDuplicate={copySubmit} onSubmitEdit={editSubmit} onSync={setRemoteConfigData} data={configdata} onClickDownLoadConfig = {downloadConfig} shape='remote' fill="table1" stroke="rowOnly" Columns={localcolumns} />}
                    </FlexBox>
                </FlexBox>
            </FlexBox>}
        </GridContainer>
    )
}
export default ConfigurationScreen